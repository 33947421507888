.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-size:1.6rem;
}

h2 {
  font-size:1.4rem;
}

h3 {
  font-size:1.1rem;
}

p {
  font-size:1rem;
}

.cardrow {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}


@media screen and (max-width: 900px) {
  h1 {
    font-size:1.5rem;
  }
  
  h2 {
    font-size:1.2rem;
  }
  
  h3 {
    font-size:1rem;
  }
  
  p {
    font-size:0.8rem;
  }

  .cardrow {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }
}


@media screen and (max-width: 600px) {
  h1 {
    font-size:1.2rem;
  }
  
  h2 {
    font-size:1rem;
  }
  
  h3 {
    font-size:0.8rem;
  }
  
  p {
    font-size:0.7rem;
  }

  .cardrow {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }
}